import { clsx } from "clsx";

type CardContainerProps = {
  readonly containerRef?: React.LegacyRef<HTMLDivElement>;
  readonly children: React.ReactNode;
  readonly className?: string;
};

export function CardContainer({
  containerRef,
  children,
  className,
}: CardContainerProps): JSX.Element {
  return (
    <div
      ref={containerRef}
      className={clsx(
        "w-fit rounded-lg border-8 border-primary-800 p-4",
        className,
      )}
    >
      {children}
    </div>
  );
}
