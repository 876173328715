import type {
  Key,
  Section,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { ChordInfoView } from "./ChordInfoView";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";

export type ChordBlocksViewProps = {
  readonly chordBlocks: Section["chordBlocks"];
  readonly degreeStyle: DegreeStyle;
};

export function ChordBlocksView({
  chordBlocks,
  degreeStyle,
}: ChordBlocksViewProps): JSX.Element {
  let currentKey: Key | undefined;

  return (
    <div className="flex w-full flex-wrap gap-y-2">
      {chordBlocks.flatMap((chordBlock, chordBlockIndex) => {
        switch (chordBlock.type) {
          case "br": {
            return [];
          }
          case "bar": {
            const isLastBarOfLine =
              chordBlocks[chordBlockIndex + 1]?.type === "br" ||
              chordBlockIndex === chordBlocks.length - 1;

            const bar = chordBlock.value;

            return bar.map((chordInfo, chordInfoIndex) => {
              const isFirstChordInBar = chordInfoIndex === 0;
              const isFirstIndexInsideCurrentBar = chordInfoIndex === 0;
              const isLastIndexInsideCurrentBar =
                chordInfoIndex === chordBlock.value.length - 1;

              const newKey = chordInfo.metaInfos.find(
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (metaInfo) => metaInfo.type === "key",
              )?.value;

              if (newKey !== undefined) {
                currentKey = newKey;
              }

              return (
                <div
                  key={`${chordBlockIndex}-${chordInfoIndex}`}
                  className="flex text-sm"
                >
                  {!isFirstIndexInsideCurrentBar && (
                    <div className="pointer-events-none flex select-none items-center">
                      ,
                    </div>
                  )}
                  <div>
                    <ChordInfoView
                      chordInfo={chordInfo}
                      currentKey={currentKey}
                      key={chordInfoIndex}
                      degreeStyle={degreeStyle}
                      chordBorder={{
                        left: isFirstChordInBar,
                        right: isLastIndexInsideCurrentBar && isLastBarOfLine,
                      }}
                    />
                  </div>
                </div>
              );
            });
          }
          default: {
            //TODO: exhaustive check
            return [];
          }
        }
      })}
    </div>
  );
}
