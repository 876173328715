import type { Ast } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { SectionView } from "./SectionView";
import type { DegreeStyle } from "~/libs/chordProgressionUtil/DegreeStyle";

export type ChordProgressionAstViewFlexProps = {
  readonly ast: Ast;
  readonly degreeStyle: DegreeStyle;
};

export function ChordProgressionAstViewFlex({
  ast,
  degreeStyle,
}: ChordProgressionAstViewFlexProps): JSX.Element {
  return (
    <div className="flex flex-col items-center gap-y-10">
      {ast.map((section, sectionIndex) => {
        return (
          <SectionView
            section={section}
            key={sectionIndex}
            degreeStyle={degreeStyle}
          />
        );
      })}
    </div>
  );
}
