import { Link } from "@remix-run/react";
import {
  ChordProgressionAstView,
  type ChordProgressionAstViewProps,
} from "../ChordProgressionAstView";
import { ChordProgressionAstViewFlex } from "../ChordProgressionAstViewFlex";
import { CardContainer } from "../CardContainer";
import { Icons } from "../_icons";
import { FavoriteButton, type FavoriteButtonProps } from "../FavoriteButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/shadcnui/ui/dropdown-menu";
import { Button } from "~/components/shadcnui/ui/button";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type ChordProgressionCardProps = {
  readonly viewType: "flex" | "grid";
  readonly artistName: string;
  readonly trackName: string;
  readonly trackDetailPageUrl?: string;
  readonly chordProgressionAstViewProps: ChordProgressionAstViewProps;
  readonly favoriteButtonProps?: FavoriteButtonProps;
  readonly subActionsProps?: {
    readonly isEditable: boolean;
    readonly onClickEdit: () => void;
    readonly onClickDelete: () => void;
    readonly onClickReport: () => void;
  };
};

// TODO: 再生ボタンつける（プルダウンでいろんな楽器選べるようにする？）。ただ、そうなるとBPMも入力必要になっちゃうな…
// TODO: 拡大表示ボタンつける。全画面みたいなアイコンで、全画面表示するなど
// TODO: グリッドかフレックスか切り替えるボタン作る
export function ChordProgressionCard({
  viewType = "flex",
  artistName,
  trackName,
  trackDetailPageUrl,
  chordProgressionAstViewProps,
  favoriteButtonProps,
  subActionsProps,
}: ChordProgressionCardProps): JSX.Element {
  const dict = useDictionary();
  const cardTitle = `${artistName} - ${trackName}`;

  const titleBlock = subActionsProps ? (
    <div className="flex justify-between gap-4 text-lg font-bold">
      <div />

      <div className="flex items-center justify-center">
        <h2>
          {trackDetailPageUrl ? (
            <Link to={trackDetailPageUrl} className="appLink">
              {cardTitle}
            </Link>
          ) : (
            cardTitle
          )}
        </h2>
        {!!favoriteButtonProps && (
          <FavoriteButton {...favoriteButtonProps} size={40} className="ml-1" />
        )}
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon">
            <Icons.Util.ThreeDots />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {!!subActionsProps.isEditable && (
            // eslint-disable-next-line react/jsx-handler-names
            <DropdownMenuItem onClick={subActionsProps.onClickEdit}>
              <Icons.Crud.Pencil size={14} className="mr-1" />
              {dict.edit}
            </DropdownMenuItem>
          )}
          {!!subActionsProps.isEditable && (
            // eslint-disable-next-line react/jsx-handler-names
            <DropdownMenuItem onClick={subActionsProps.onClickDelete}>
              <Icons.Crud.Trash size={14} className="mr-1" /> {dict.delete}
            </DropdownMenuItem>
          )}
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <DropdownMenuItem onClick={subActionsProps.onClickReport}>
            <Icons.Util.Report size={14} className="mr-1" />
            {dict.report}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  ) : (
    <div className="flex items-center justify-center text-lg font-bold">
      <h2>
        {trackDetailPageUrl ? (
          <Link to={trackDetailPageUrl} className="appLink">
            {cardTitle}
          </Link>
        ) : (
          cardTitle
        )}
      </h2>
      {!!favoriteButtonProps && (
        <FavoriteButton {...favoriteButtonProps} size={40} className="ml-1" />
      )}
    </div>
  );

  return (
    <CardContainer>
      {titleBlock}

      <div className="my-16">
        {viewType === "flex" && (
          <ChordProgressionAstViewFlex {...chordProgressionAstViewProps} />
        )}
        {viewType === "grid" && (
          <ChordProgressionAstView {...chordProgressionAstViewProps} />
        )}
      </div>
    </CardContainer>
  );
}
